@font-face {
    font-family: "GoodTimesRg-Regular";
    src: url("good-times-rg.ttf");
}

* {
    background-color: #100045;
    color: white;
}

.box {
    background: linear-gradient(#100045, #100045) padding-box, linear-gradient(to right bottom, #df4b90, #f1906e, #6232a2);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    block-size: fit-content;
    border: 0.35em solid transparent;
    border-radius: 20px;
    display: grid;
    margin: 10% auto 0 auto;
    min-height: 13em;
    max-width: 95%;
    width: 50em;
}

.box-button {
    font-family: "GoodTimesRg-Regular";
    height: 2.25em;
    margin: 0em 0.25em 0em 0em;
    padding: 0.25em 1em;
}

.box-component {
    background: linear-gradient(white, white) padding-box, linear-gradient(to right bottom, #6232a2, #df4b90, #f1906e);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    border: 0.2em solid transparent;
    border-radius: 3px;
    box-sizing: border-box;
    color: #100045;
}

.box-element {
    border: 0.2em solid transparent;
    border-radius: 60px;
}

.box-input {
    cursor: default;
    height: 2.25em;
    margin: 0em 0.25em 0em 0.25em;
    padding: 0.25em 1em;
    width: 50%;
}

.box-textarea {
    cursor: default;
    height: 10.0em;
    margin: 0em 0.25em 0em 0.25em;
    padding: 0.25em 1em;
    resize: none;
    width: 60%;
}
.collapsible-retrieved-text {
    overflow-wrap: anywhere;
    word-wrap: anywhere;
}

.copy-link-image {
    height: 1em;
    padding: 0.25em 0.25em 0.2em 0.5em;
    vertical-align: bottom;
    width: 1em;
}

.copy-link-tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.copy-link-tooltip .copy-link-tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.copy-link-tooltip:hover .copy-link-tooltip-text {
    visibility: visible;
}

.download-audio-duration-text {
    align-items: center;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 0.25em;
}

.download-audio-file-type {
    justify-content: center;
    margin: 0 auto 0 auto;
    padding: 0 0 0.25em 0;
    text-align: center;
}

.download-audio-file-type-fieldset {
    background: linear-gradient(#100045, #100045) padding-box, linear-gradient(to left top, #6232a2, #df4b90, #f1906e);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    border: 0.2em solid transparent;
    border-radius: 3px;
    border-style: solid;
    box-sizing: border-box;
    color: #100045;
}

.download-audio-file-type-radio-buttons {
    padding: 0.25em 0 0 0;
}

.download-audio-player {
    align-items: center;
    display: grid;
    height: 4em;
    justify-content: center;
    margin: 0 auto 0 auto;
    max-width: 90%;
    position: relative;
    width: 42em;
}

.download-back-button {
    background: transparent;
    border: 0em;
    box-sizing: border-box;
    font-family: "GoodTimesRg-Regular";
    font-size: 1em;
    justify-content: center;
    margin: 0.5em 0 0.5em 0.5em;
}

.download-back-button-container {
    border-bottom: 1.5px solid #ccc;
    box-sizing: border-box;
    display: grid;
    justify-content: left;
}

.download-box {
    align-items: center;
}

.download-button {
    border: 0em;
    background: linear-gradient(45deg, #6232a2, #df4b90, #f1906e);
    border: 1.5px solid transparent;
    border-color: rgb(231, 231, 231);
    border-radius: 3px;
    box-sizing: border-box;
    color: #f0eded;
    font-family: "GoodTimesRg-Regular";
    font-size: calc(0.5em + 1.5vh + 1.1vw);
    padding: 0.75em 1em;
}

.download-button-progress-bar-wrapper {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-sizing: border-box;
    display: grid;
    justify-content: center;
    padding: 0.5em 2em 0.5em 2em;
}

.download-collapsible-header {
    background-color: #6232a2;
    border-radius: 50px 50px 0px 0px;
    cursor: pointer;
    padding: 0.25em;
}

.download-collapsible-header:hover,
.toggle-active {
    background-color: #f1906e;
}

.download-collapsible-header:after {
    /* Unicode character for "plus" sign (+) */
    content: "\02795";
    color: white;
    float: right;
    font-size: 10px;
    margin: 0.5em 1.25em 0.25em 1em;
}

.download-collapsible-header,
.toggle-active:after {
    /* Unicode character for "minus" sign (-) */
    content: "\2796";

}

.download-collapsible-text {
    display: none;
}

.download-detailed-synthesis-status {
    display: grid;
    justify-content: center;
    overflow-wrap: anywhere;
    text-align: left;
    word-wrap: anywhere;
}

.download-page {
    margin: 0 auto;
    width: auto;
}

.download-progress {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    text-align: center;
}

.download-progress-bar {
    height: 50px;
    width: 200px;
}

.download-progress-bar-text {
    display: grid;
    justify-content: center;
    overflow-wrap: anywhere;
    padding: 0em 0em 0em 0em;
    word-wrap: anywhere;
}

.download-synthesis-started-text {
    align-items: center;
    display: grid;
    justify-content: center;
    line-height: 150%;
    margin: 0 auto 0 auto;
    max-width: 90%;
    text-align: center;
    width: 50em;
}

.download-synthesis-status {
    display: grid;
    margin: 0.5em auto 0 auto;
    max-width: 90%;
    text-align: center;
    width: 42em;
}

.download-synthesized-text {
    display: grid;
    margin: 0 auto;
    max-width: 95%;
    text-align: center;
    width: 50em;
}

.download-text {
    border-radius: 20px;
    box-sizing: border-box;
    display: grid;
    justify-content: center;
    margin: 0 auto 0 auto;
    overflow-wrap: anywhere;
    padding: 0.5em 1em 0.5em 1em;
    text-align: center;
    word-wrap: anywhere;
}

.download-user-message-text {
    align-items: center;
    display: inline-block;
    font-weight: bold;
    justify-content: center;
    overflow-wrap: anywhere;
    margin: 1em 0em 1em 0em;
    padding: 0.5em 3em 0.5em 3em;
    word-wrap: anywhere;
}

.error-message {
    background-color: rgba(178, 34, 34, 0.4);
    border: 5px solid;
    border-color: rgba(178, 34, 34, 0.8);
    border-radius: 10px;
    font-size: 1.125em;
    font-weight: bold;
    text-align: center;
}

.file-upload-metadata {
    align-items: center;
    display: grid;
    justify-content: center;
}

.file-upload-metadata-text {
    margin: 0;
}

.file-upload-metadata-wrapper {
    align-items: center;
    display: flex;
    height: 2em;
    justify-content: center;
}

.file-upload-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 100%;
    padding: 3em 0em 1em 0em;
}

.explainer {
    margin: 0 auto;
    text-align: center;
    width: min(45em, 75%);
}

.no-link-tabs {
    border-radius: 20px;
}

.page-title {
    background-color: #df4b90;
    background-image: linear-gradient(to right, #6232a2, #df4b90, #f1906e);
    background-size: 100%;
    bottom: 0.5em;
    display: inline-block;
    font-family: "GoodTimesRg-Regular";
    font-size: 1.5em;
    margin: 0.5em 0.5em 0.5em 0.5em;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.page-title-logo {
    padding: 1.0em 0 0em 0.25em;
    width: 4em;
}

.react-player {
    left: 0;
    position: absolute;
    top: 0;
}

.sample-audio-player {
    align-items: center;
    display: grid;
    height: 2.5em;
    justify-content: center;
    margin: 0 auto 0 auto;
    max-width: 90%;
    position: relative;
    width: 60%;
}

.sample-tab {
    align-items: center;
    display: grid;
    justify-content: center;
}

.sample-tab-select-box {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 100%;
    padding: 3em 0em 1em 0em;
}

.submission-error-message {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}

.submission-error-message p {
    align-items: center;
    display: inline-block;
    padding: 0.5em 3.0em 0.5em 3.0em;
}

.synthesis-tab {
    margin: 0 auto;
    max-width: 100%;
    width: auto;
}

.synthesis-website-url {
    margin: auto;
    max-width: 95%;
    overflow-wrap: break-word;
    text-align: center;
    word-break: break-word;
    word-wrap: break-word;
}

.tab-list {
    border-bottom: 1.5px solid #ccc;
    padding-left: 0;
    margin: 0;
    max-width: 100%;
}

.tab-list-item {
    display: inline-block;
    font-family: "GoodTimesRg-Regular";
    list-style: none;
    padding: 0.5rem 0.75rem;
    border-radius: 14px 5px 0 0;
}

.tab-list-active {
    border: solid #ccc;
    border-width: 1.75px 1.75px 0 1.75px;
}

.tab-box-list {
    border-bottom: 1.5px solid #ccc;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-left: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
}

.tab-box-list-item {
    display: inline-block;
    font-family: "GoodTimesRg-Regular";
    list-style: none;
    padding: 0.5rem 0.75rem;
    border-radius: 14px 5px 0 0;
}

.tab-box-list-active {
    border: solid #ccc;
    border-width: 1.75px 1.75px 0 1.75px;
}

.tab-content {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.text-tab-textarea-box {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 100%;
    padding: 1em 0em 1em 0em;
}

.web-tab-audio-player {
    align-items: center;
    display: flex;
    justify-content: center;
}

.web-tab-input-box {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 100%;
    padding: 3em 0em 1em 0em;
}
